<template>
  <div ref="chat" class="chat">
    <div class="u-flex u-flex--col">
      <div class="response">
        <ChatAdminMessageHeader/>
        <p class="response__text">{{ chat_message.text }}</p>
      </div>
    </div>
    <div class="messages-container">
      <div v-for="(group, key) in messagesPayload" :key="key" class="u-flex u-flex--col message-box">
        <Chip :text="formatChipToDayMonth(group.date)" class="chip" />
        <div v-for="(message, key) in group.items" :key="key">
          <div v-if="message.sender.alias === 'administrator'" class="u-flex u-flex--col">
            <div class="response" @contextmenu="onAdminMessageRightClick($event, message)">
              <div v-if="message.reply_to_id" class="replied-message">
                <ChatAdminMessageHeader/>
                <span>{{ message.replied_message.text }}</span>
              </div>
              <ChatAdminMessageHeader/>
              <p class="response__text">{{ message.text }}</p>
              <div v-if="message.files.length > 0">
                <ChatFilePreview
                  v-for="file in message.files"
                  :key="file.id"
                  :file="file" />
              </div>
              <div class="u-flex u-ai-center request__container">
                <span v-if="message.created_at.substr(-8) !== message.updated_at.substr(-8)" style="font-size: 12px;">изменено</span>

                <Icon v-if="!message.is_read" name="icon_send" class="request__isread" />
                <Icon v-else name="icon_isread" class="request__isread" />

                <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
              </div>
            </div>
          </div>
          <div v-else class="u-flex u-flex--col">
            <div class="request" @contextmenu="onMessageRightClick($event, message)">
              <div v-if="message.reply_to_id" class="replied-message">
                <strong v-if="message.replied_message?.sender_object_id == message.sender?.id">Вы</strong>
                <ChatAdminMessageHeader v-else/>
                <span>{{ message.replied_message.text }}</span>
                <!-- <span class="italic" v-else>Сообщение удалено</span> -->
              </div>
              <p class="request__text">{{ message.text }}</p>

              <div v-if="message.files.length > 0">
                <ChatFilePreview
                  v-for="file in message.files"
                  :key="file.id"
                  :file="file" />
              </div>

              <div class="u-flex u-ai-center request__container">
                <span v-if="message.created_at.substr(-8) !== message.updated_at.substr(-8)" style="font-size: 12px;">изменено</span>

                <Icon v-if="!message.is_read" name="icon_send" class="request__isread" />
                <Icon v-else name="icon_isread" class="request__isread" />

                <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="end" class="end"></div>
  </div>

  <ContextMenu ref="menu" :model="contextMenuOptions" />
  <ContextMenu ref="replyMenu" :model="replyMenuOption" />
  <ConfirmPopup>
    <template #message="slotProps">
      <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 p-4 mb-4 pb-0">
        <i :class="slotProps.message.icon" class="text-6xl text-primary-500"></i>
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>
  <Transition>
    <ChatConfirmPopup
      v-if="isMessageRemovingConfirmVisible"
      :style="{ position: floatPosition }"
      :text="$t('message_remove_confirm_text')"
      @close="onMessageRemoveCancel"
      @confirm="onMessageRemoveConfirm"/>
  </Transition>
  <div v-if="quoteMessageId" class="edit-container">
    <IconReply class="edit-container__icon" />
    <div style="justify-content: space-between; display: flex; width: 100%; align-items: center;">
      <div style="display: flex; flex-direction: column; gap: 3px">
        <span style="color: #6A7B95;font-size: 14px;font-weight: 500;">Ответить на сообщение</span>
        <div style="color: #191B23;">
          {{ quoteMessageText }}
        </div>
      </div>
      <span style="cursor: pointer; font-size: 20px;" class="pi pi-times" @click="clearEditValues"></span>
    </div>
  </div>

  <div v-if="editMessageId" class="edit-container">
    <IconEdit class="edit-container__icon" />
    <div style="justify-content: space-between; display: flex; width: 100%; align-items: center;">
      <div style="display: flex; flex-direction: column; gap: 3px">
        <span style="color: #6A7B95;font-size: 14px;font-weight: 500;">Редактировать сообщение</span>
        <div style="color: #191B23;">
          {{ editMessageText }}
        </div>
      </div>
      <span style="cursor: pointer; font-size: 20px;" class="pi pi-times" @click="clearEditValues"></span>
    </div>
  </div>

  <ChatInputWrapper
    ref="inputWrapper"
    :message="newChatMessage"
    :position="floatPosition"
    @select-emoji="onInputEmoji"
    @change-message="onInputMessage"
    @send-message="onSendMessage"/>
</template>

<script>
import { defineComponent } from 'vue';
import { getChannelConnector } from '@/core/channels/channel';
import { Icon } from '@brskl/ui-lib';
import IconReply from '@/assets/icons/icon_reply.svg';
import IconEdit from '@/assets/icons/icon_edit.svg';
import Chip from '@/apps/reports/component/Chip.vue';
import ChatInputWrapper from '@/core/components/Chat/ChatInputWrapper';
import ChatFilePreview from '@/core/components/Chat/ChatFilePreview';
import ChatAdminMessageHeader from '@/core/components/Chat/ChatAdminMessageHeader';
import ChatConfirmPopup from '@/core/components/Chat/ChatConfirmPopup';
import { supportAPI } from '@/core/api/_support';
import { companyAPI } from '@/core/api/company.js';

import { uniqBy } from 'lodash';
import { format, compareAsc } from 'date-fns';

import ContextMenu from 'primevue/contextmenu';
import ConfirmPopup from 'primevue/confirmpopup';
import 'primeicons/primeicons.css'
import { mapActions } from 'vuex';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export default defineComponent({
  // @ts-ignore
  components: {
    ChatInputWrapper,
    ChatFilePreview,
    ChatAdminMessageHeader,
    ChatConfirmPopup,
    Icon,
    IconReply,
    IconEdit,
    Chip,
    ContextMenu,
    ConfirmPopup,
  },
  props: {
    messages: {
      type: Object,
    },
    detachedMode: {
      type: Boolean,
      default: false
    },
    floatPosition: {
      type: String,
      default: 'fixed',
      validate: (value) => ['fixed', 'absolute'].includes(value),
    },
  },

  emits: ['sendMessage', 'refetch'],

  data() {
    return {
      txt: 'txt',
      date: '20-04-2021',
      person: 'meme memev',
      newChatMessage: '',
      editMessageText: '',
      editMessageId: 0,
      quoteMessageId: 0,
      quoteMessageText: '',
      messagesList: [],
      contextMenuEntityTarget: {
        files: []
      },
      isMessageRemovingConfirmVisible: false,
      chat_message: {
        chat: {
          administrator_id: 48,
          company_id: 0,
          customer: null,
          employee: null,
          funnel: null,
          id: -1,
          last_comment: null,
          last_message: null,
          name: '',
          status: 1,
        },
        chat_id: 2633,
        created_at: this.formatDateToTime(new Date().toISOString()),
        files: [],
        id: -1,
        is_read: false,
        order: [],
        order_id: 0,
        reply_to_id: 0,
        reply_to_message: [],
        sender: {
          alias: 'administrator',
          id: 48,
          name: 'Briskly',
        },
        text: this.$t('support_service_greeting'),
        type: 0,
        scrollTimeout: null,
      },
    };
  },

  computed: {
    groupedItems() {
      if (!this.messagesList) {
        return;
      }

      const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');
      const list = this.messagesList
        .map((item) => item.created_at.split(' ').join('T'))
        .sort((d1, d2) => compareAsc(new Date(d1), new Date(d2)))
        .map((item) => formatDate(item))

      const dates = uniqBy(
        list,
      );

      const store = {};

      dates.forEach((date) => (store[date] = []));

      this.messagesList.forEach((item) => {
        const key = formatDate(item.created_at.split(' ').join('T'));
        store[key].push(item);
      });

      const result = [];

      for (const [date, items] of Object.entries(store)) {
        const messages = items.filter(m => {
          if (typeof m.deleted_at !== 'undefined') {
            return m.deleted_at == '0000-00-00 00:00:00'
          } else {
            m.deleted_at = '0000-00-00 00:00:00'
            m.updated_at = m.created_at
            return true
          }
        })
        result.push({ date, items: this.sortMessages(messages) });
      }

      return result;
    },
    messagesPayload() {
      return this.groupedItems
    },
    contextMenuOptions() {
      if (!this.contextMenuEntityTarget) {
        return [{ label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() }]
      }

      if (this.contextMenuOptions?.files?.length > 0) {
        return [
          { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
          { label: 'Удалить', icon: 'pi pi-trash', command: () => this.showMessageConfirmPopup() },
        ]
      }

      return [
        { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
        { label: 'Редактировать', icon: 'pi pi-pencil', command: () => this.startEditingMessage() },
        { label: 'Удалить', icon: 'pi pi-trash', command: () => this.showMessageConfirmPopup() },
      ]
    },
    replyMenuOption() {
      return [
        { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
      ]
    },
  },

  methods: {
    ...mapActions({
      add: 'core$notifications/add',
      setChat: 'core$notifications/setChat',
    }),

    clearEditValues() {
      this.contextMenuEntityTarget = {files: []};
      this.editMessageId = 0;
      this.editMessageText = '';
      this.newChatMessage = '';
      this.quoteMessageId = 0;
      this.quoteMessageText = '';
    },

    startEditingMessage() {
      const { id, text } = this.contextMenuEntityTarget
      this.editMessageId = id
      this.editMessageText = "" + text
      this.newChatMessage = text
    },

    startQuotingMessage() {
      const { id, text } = this.contextMenuEntityTarget
      this.quoteMessageId = id
      this.quoteMessageText = text
    },

    showMessageConfirmPopup() {
      this.isMessageRemovingConfirmVisible = true;
    },

    onMessageRemoveCancel() {
      this.isMessageRemovingConfirmVisible = false;
    },

    async onMessageRemoveConfirm() {
      this.isMessageRemovingConfirmVisible = false;

      let messageIndex
      const { id } = this.contextMenuEntityTarget

      await supportAPI.deleteMessage({ id });
      // await this.fetchMessages()

      const messages = this.messagesList
      messages.every((m, idx) => {
        if (messageIndex) {
          return false
        }

        if (m.id == id) {
          messageIndex = idx
        }

        return true
      })

      this.messagesList.map(k => {
        if (k.id == id) {
          k.deleted_at = true
        }
      })
    },

    async updateMessage() {
      const messageUpdate = await supportAPI.updateMessage({ id: this.editMessageId, text: this.newChatMessage });
      this.messagesList.map(k => {
        if (k.id == this.editMessageId) {
          k.text = messageUpdate?.message.text
          k.updated_at = messageUpdate?.message.updated_at
        }
      })
      this.clearEditValues()
    },

    formatDateToTime(date) {
      const _date = date.split(' ').join('T');
      return format(new Date(_date), 'HH:mm');
    },

    onInputEmoji(emoji) {
      this.newChatMessage = this.newChatMessage + emoji.native
    },

    onInputMessage(message) {
      this.newChatMessage = message;
    },

    onMessageRightClick(e, message) {
      this.$refs.menu.show(e)
      this.contextMenuEntityTarget = message
    },

    onAdminMessageRightClick(e, message) {
      this.$refs.replyMenu.show(e)
      this.contextMenuEntityTarget = message
    },

    formatChipToDayMonth(date) {
      if (!date) {
        return;
      }

      const _date = date.split(' ').join('T');
      const month = 'MONTH_' + format(new Date(_date), 'MM');
      const day = format(new Date(_date), 'dd');
      return day + ` ${this.$t(month)}`;
    },

    async sendNewChatMessage(text) {
      if (text.trim() === '') return;
      const response = await companyAPI.sendMessage({ text });
    },

    async onSendMessage() {
      const { inputWrapper } = this.$refs;
      const files = inputWrapper.getAttachedFiles();
      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          const base64Contents = await toBase64(file);
          try {
            await supportAPI.uploadFile({ file: base64Contents });
          } catch (e) {
            console.error(e);
          }
        });
        await Promise.all(uploadPromises);
        inputWrapper.resetAttachedFiles();
      }

      if (this.editMessageId) {
        await this.updateMessage()
        // await this.fetchMessages()
        this.newChatMessage = '';
        return false;
      }

      if (this.quoteMessageId) {
        await supportAPI.replyToMessage({ text: this.newChatMessage, reply_to: this.quoteMessageId })
        this.clearEditValues()
        // await this.fetchMessages()
        return false;
      }

      // if (this.detachedMode) {
      await this.sendNewChatMessage(this.newChatMessage)
        // await this.fetchMessages(50)
      // }

      this.newChatMessage = '';
      this.$emit('sendMessage', this.newChatMessage);
      this.$emit('refetch')
    },

    scrollToBottom(smooth = true) {
      this.$refs.end.scrollIntoView();
    },

    async fetchMessages(timeout = 500) {
      try {
        let messagesProcessed = false
        const payload = {
          limit: 9999,
          page: 1,
        };

        let { items } = await companyAPI.getMessages(payload);
        items = items.map((i) => ({ ...i, created_at: i.created_at.split(' ').join('T') }));

        const messagesProcessor = (message) => {
          return new Promise(resolve => resolve(this.handleChatMessage({ chat_message: message })))
        }
        const messages = items.map((el) => messagesProcessor(el));
        setTimeout(() => this.scrollToBottom(), timeout)
      } catch (error) {
        console.error(error);
      }
    },

    updateMessageFromSocket(message) {
        this.messagesList.map(k => {
            if (k.id == message.id) {
                k.text = message.text
                k.created_at = message.created_at
                k.deleted_at = message.deleted_at
                k.updated_at = message.updated_at
                k.is_read = message.is_read
                k.reply_to_id = message.reply_to_id
                k.reply_to_message = message.replied_message
            }
        })
    },
    deleteMessageFromSocket(message_id) {
        this.messagesList.map(k => {
            if (k.id == message_id) {
                k.deleted_at = true
            }
        })
    },

    async handleChatMessage(data, fromSocket = false) {
      const message = data.chat_message

      if (message) {
        if (message?.is_delete) {
            return this.deleteMessageFromSocket(message.message.id)
        }
        if (message?.is_update || message?.is_answer) {
            console.log(message.message)
            message.message.updated_at = '0000-00-00 00:00:00'
            return this.updateMessageFromSocket(message.message)
        }
      }
      if (!message.deleted_at) {
        message.deleted_at = '0000-00-00 00:00:00'
        message.updated_at = message.created_at
      }

      if (message.reply_to_message) {
        message.replied_message = message.reply_to_message
      }

      this.messagesList.push(message);

      if (fromSocket) {
        setTimeout(() => this.scrollToBottom(), 100)
      }

      if (this.revealChat && data.chat_message.is_read === false) {
        await companyAPI.setAsRead({ id: data.chat_message.id });
      } else if (!this.revealChat && data.chat_message.is_read === false && data.chat_message.sender.alias === 'administrator') {
        this.add({
          message: data.chat_message.text,
          type: 'chat-message',
          source: 'handleChatMessage',
          title: this.$t('Новое сообщение'),
        });
      }
    },

    sortMessages(messages) {
      return messages.sort((m1, m2) => compareAsc(new Date(m1.created_at), new Date(m2.created_at)));
    },

    handleWindowFocus() {
      getChannelConnector().reconnectIfClosed();
    },
  },

  mounted() {
    bus.on('core$chat/newChatMessage', (data) => this.handleChatMessage(data, true));
    this.fetchMessages();
    window.addEventListener('focus', this.handleWindowFocus);
  },

  unmounted() {
    clearTimeout(this.scrollTimeout);
    bus.off('core$chat/newChatMessage', this.handleChatMessage);
    window.removeEventListener('focus', this.handleWindowFocus);
  },
});
</script>

<i18n>
{
  "ru": {
    "message_remove_confirm_text": "Вы действительно хотите удалить это сообщение?"
  },
  "en": {
    "message_remove_confirm_text": "Are you sure you want to delete this message?"
  }
}
</i18n>

<style lang="scss" scoped>
.replied-message {
  border-left: 2px solid #22C951;
  font-size: 12px;
  color: #515C72;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.replied-message span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.italic {
  font-style: italic;
}

.edit-container {
  position: sticky;
  bottom: 64px;
  background: #E9EBF3;
  border-radius: 8px;
  display: flex;
  left: 0;
  right: 0;
  margin-left: 32px;
  margin-right: 32px;
  gap: 20px;
  padding: 14px 12px;
  align-items: center;

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: #515C72;
  }
}

.messages-container {
  height: 100%;
  flex-grow: 1;
  padding-bottom: 0;
}

:deep(.base-input__field) {
  padding-left: 35px !important;
}

.p-fileupload {
  position: absolute;
  top: 6px;
  bottom: 0;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  left: 10px;
  margin: auto;
}

:deep(.p-fileupload-header) {
  border: 0 !important;
}

.chat {
  --message-border-radius: 8px;

  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding-bottom: 150px;
}

.response {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  min-height: 72px;
  min-width: 243px;
  max-width: 600px;
  background: rgba(196, 211, 234, 0.5);
  border-radius: var(--message-border-radius);
  border-bottom-left-radius: 0;
  margin: 8px 32px;
  padding: 12px;
  word-break: break-all;

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
    align-self: flex-end;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
  }
}

.request {
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  min-height: 52px;
  min-width: 243px;
  max-width: 600px;

  background: #e9ebf3;
  border-radius: var(--message-border-radius);
  border-bottom-right-radius: 0;
  padding: 12px;
  margin: 8px 32px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    align-self: flex-start;
  }

  &__container {
    align-self: flex-end;
  }

  &__isread {
    width: 18px !important;
    height: 12px !important;
    margin: 0 6px 0 0;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
  }
}

.chip {
  width: 100px;
  white-space: nowrap;
  align-self: center;
  cursor: default;
  user-select: none;
  margin: 31px 0;
}

@media (max-width: 768px) {
  .request, .response {
    margin: 8px 16px;
  }
}
</style>
