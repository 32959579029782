export default {
  misc: {
    accounts: 'Счета',
    new_payment_invoice: 'Новый счёт на оплату',
    acquiring: 'Эквайринг',
    briskly_catalog: 'Каталог Briskly',
    buy_device: 'Купить устройство',
    catalogs_categories: 'Каталоги и категории',
    new_catalog: 'Новый каталог',
    new_category: 'Новая категория',
    new_discount: 'Новая скидка',
    discounts_on_goods: 'Скидки на товары',
    discounts_on_category: 'Скидки на категорию',
    discounts_on_order: 'Скидки на заказ',
    discounts_on_good: 'Скидки на товар',
    adding_discount: 'Добавление скидки',
    acts: 'Акты',
    sign_document: 'Подписать документ',
    legal_entity_id: 'Юридическое лицо ID :id',
    new_legal_entity: 'Новое юридическое лицо',
    legal_entity: 'Юридическое лицо',
    event_page: 'Страница события',
    telemetry: 'Телеметрия',
    telemetry_page: 'Страница телеметрии',
    questions_answers: 'Вопросы и ответы',
    new_product: 'Новый товар',
    good_page: 'Страница товара',
    mailings: 'Рассылки',
    home: 'Главная',
    updates: 'Обновления',
    marketplace: 'Маркетплейс',
    warehouse: 'Склад',
    new_menu: 'Новое Меню',
    menu_page: 'Страница меню',
    operations: 'Операции',
    payments: 'Оплаты',
    charges: 'Платежи',
    payment_id: 'Оплата ID',
    return_processing: 'Оформление возврата',
    main_information: 'Основная информация',
    returns: 'Возвраты',
    create_return: 'Создание возврата',
    profile_settings: 'Настройки профиля',
    user_profile: 'Профиль пользователя',
    compensations: 'Возмещения',
    registration: 'Регистрация',
    stock: 'Остатки',
    warehouse_stock: 'Склад / Остатки',
    reports: 'Отчеты',
    marketing: 'Маркетинг',
    movement_reports: 'Отчёты по движению',
    create_move_report: 'Создать отчет по движению',
    warehouse_reports: 'Склад / Отчёты по движению',
    warehouse_report: 'Склад / Отчет :id',
    supply_id: 'ID поставки',
    new_supply: 'Новая поставка',
    warehouse_supplies: 'Склад / Поставки',
    warehouse_supply: 'Склад / Поставка',
    create_supply: 'Создание поставки',
    edit_supply: 'Редактирование поставки',
    terminal_advert: 'Реклама на терминале',
    new_ad_block: 'Новый рекламный блок',
    virtual_terminal_id: 'Виртуальный терминал :id',
    new_virtual_terminal: 'Новый виртуальный терминал',
    virtual_terminal: 'Виртуальный терминал',
    devices: 'Устройства',
    new_trade_point: 'Новая торговая точка',
    catalog_binding: 'Привязка каталога',
    adding_goods: 'Добавление товаров',
    integration: 'Интеграция',
    create_catalog: 'Создание каталога',
    create_good: 'Создание товара',
    edit_trade_point: 'Редактирование торговой точки',
    create_trade_point: 'Создание торговой точки',
    movement_id: 'Перемещение :id',
    new_movement: 'Новое перемещение',
    warehouse_transfers: 'Склад / Перемещения',
    warehouse_transfer: 'Склад / Перемещение',
    create_transfer: 'Создание перемещения',
    edit_movement: 'Редактирование перемещения',
    new_writeoff: 'Новое списание',
    write_off: 'Списание :id',
    warehouse_write_offs: 'Склад / Списания',
    write_offs_write_off: 'Списания / Списание',
    create_write_off: 'Создание списания',
    edit_write_off: 'Редактирование списания',
    not_taxable: 'Не облагается',
    settlement_5_105: 'Расчётная 5/105',
    settlement_7_107: 'Расчётная 7/107',
    settlement_10_110: 'Расчётная 10/110',
    settlement_20_120: 'Расчётная 20/120',
    no_preheating: 'Без разогрева',
    min_power: 'Минимальная мощность',
    average_power: 'Средняя мощность',
    max_power: 'Максимальная мощность',
    meter: 'м',
    hour: 'Час',
    day: 'День',
    year: 'Год',
    self_service_store: 'Магазин самообслуживания',
    micromarket: 'Микромаркет',
    online_showcase: 'Онлайн-витрина',
    preorder: 'Предзаказ',
    production_delivery: 'Производство с доставкой',
    coffee_machine: 'Кофемашина',
    gas_station: 'АЗС',
    cigarette_cabinet: 'Сигаретный шкаф',
    module: 'Модуль',
    instruction: 'Инструкция',
    horizontal_instruction: 'Горизонтальная инструкция',
    micromarket_templates: 'Макеты оклейки микромаркета',
    on_glass: 'На стекло',
    side_stickers: 'Боковые стикеры',
    to_advertising_block: 'На рекламный блок',
    r10ns_no_ad: 'R10NS (без рекламного блока)',
    r10nsg_with_ad: 'R10NSG (с рекламным блоком)',
    desktop_banner: 'Настольный баннер',
    goods_showcase_link: 'Ссылка на витрину товаров',
    your_discount_name: 'Название вашей скидки в бэкофисе',
    user_view_brand: 'То, что будет видеть пользователь во вкладке % поверх бренда компании',
    mon_wed_fri: 'ПН, СР, ПТ',
    description_more: 'Описание...',
    minimum: 'Минимальный',
    maximum: 'Максимальный',
    goods_cart_movement: 'Движения товаров в корзинах',
    t: 'Т',
    month: 'М',
    added: 'Добавлен',
    subscribed: 'Подписан',
    settlement_acts: 'Акты взаиморасчётов',
    bind_to_trade_point: 'Привязать к торговой точке',
    event: 'Событие',
    in_30_minutes: 'За 30 минут',
    in_1_minute: 'За 1 минуту',
    export_all_goods: 'Экспорт всех товаров',
    today: 'сегодня',
    view_only: 'Только просмотр',
    view_and_pay: 'Просмотр и оплата',
    menu_name: 'Название меню',
    menu_id_or_name: 'ID или название меню',
    trade_point_id_name: 'ID и название торговой точки',
    catalog_id_and_name: 'ID и название каталога',
    return_completed: 'Возврат оформлен!',
    payment_receipt: 'Чек оплаты',
    export_payment: 'Экспорт оплаты',
    trade_point_address: 'Адрес торговой точки',
    week: 'Неделя',
    counterparties: 'Контрагенты',
    add_counterparty: 'Добавить контрагента',
    create_counterparty: 'Создать нового контрагента',
    dealer: 'Дилер',
    distributor: 'Дистрибьютор',
    manufacturer: 'Производитель',
    merchant: 'Мерчант',
    service_company: 'Сервисная компания',
    sending: 'Отправка',
    balance_acceptance: 'Приемка на баланс',
    sending_details: 'Детали отправки',
    diagnostics: 'Диагностика',
    report_number: 'Отчет №',
    report_name: 'Название отчета',
    export_report: 'Экспорт отчета',
    delete_report: 'Удалить отчет',
    update_success: 'Обновление успешно',
    ad_block_name: 'Название рекламного блока',
    create_terminal: 'Создание терминала',
    terminal_details: 'Реквизиты терминала',
    terminal_trade_points: 'Торговые точки терминала',
    activation_error: 'Ошибка активации',
    no_employees: 'Нет сотрудников',
    no_catalog: 'Отсутствует каталог',
    no_schedule: 'Нет расписания',
    changes_saved: 'Изменения сохранены',
    enter_catalog_name: 'Введите название каталога',
    transfer_id: 'ID перемещения',
    from_where: 'Откуда',
    where_to: 'Куда',
    movement_number: 'Перемещение №',
    export_transfer: 'Экспорт перемещения',
    write_off_id: 'ID списания',
    write_off_status: 'Статус списания',
    export_write_off: 'Экспорт списания',
    store: 'Магазин',
    payment_name: 'Наименование платежа',
    export_device: 'Экспорт устройства',
    delete_device: 'Удалить устройсвто',
    order_number: 'Заказ №',
    client: 'Клиент',
    goods_quantity: 'Количество товара',
    model: 'Модель',
    device_model: 'Модель устройства',
    trade_point_id: 'ID тор. точки',
    trade_point_name: 'Название торговой точки...',
    initial_balance: 'Нач. остаток',
    initial_amount: 'Нач. сумма',
    receipt: 'Поступление',
    expense: 'Расход',
    final_balance: 'Итоговый остаток',
    total_sum: 'Итог. сумма',
    requisites_status: 'Статус реквизитов',
    total: 'Итого',
    date_required: 'Необходимо выбрать дату',
    time_required: 'Необходимо выбрать время',
    completed_success: 'успешно завершена',
    catalog_no: 'Каталог №',
    delete_catalog: 'Удалить каталог',
    export_catalog: 'Экспорт каталога',
    category_no: 'Категория №',
    activate_category: 'Активация категории',
    deactivate_category: 'Деактивация категории',
    delete_category: 'Удалить категорию',
    move_to_catalog: 'Переместить в каталог',
    discount_percent: 'Скидка в процентах',
    discount_rubles_fixed: 'Скидка в рублях (фиксированная скидка)',
    in_percentage: 'в процентах',
    in_currency: 'в валюте',
    good_number: 'Товар №',
    discount_no: 'Скидка №',
    in_app: 'В приложении',
    barcode_invalid: 'Штрихкод не соответствует стандарту',
    standard: 'Стандарт',
    expiry_date: 'Срок годности',
    number_of_stickers: 'Кол-во наклеек',
    total_price: 'Итоговая цена',
    show_7: 'Показать по 7',
    show_10: 'Показать по 10',
    show_25: 'Показать по 25',
    show_50: 'Показать по 50',
    order_name: 'Название заказа',
    order_composition: 'Состав заказа',
    order_date_time: 'Дата и время заказа',
    payment_type: 'Тип оплаты',
    commission: 'Комиссия',
    transaction_date: 'Дата транзакции',
    payment_order_id: 'ID платёжного поручения',
    application: 'Приложение',
    return_status: 'Статус возврата',
    delivery_type: 'Тип доставки',
    delivery_price: 'Цена доставки',
    delivery_cost: 'Стоимость доставки',
    discounted_amount: 'Сумма со скидкой',
    promo_code_name: 'Название промокода',
    in_menu: 'В меню',
    online: 'В сети',
    design: 'Дизайн',
    personal_data: 'Персональные данные',
    sold: 'Продано',
    organization_tax_id: 'ИНН организации',
    operation_time_zone: 'Время операции указывается по часовому поясу торговой точки.',
    transfer_operation_time: 'Время операции указывается по часовому поясу торговой точки из которой перемещается товар.',
    order_id: 'ID заказа',
    size_format: 'Размер и формат',
  }
};
